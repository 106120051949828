import React from "react";

export default class Navbar extends React.Component {
  render() {
    return (
      <div className="nav">
        {/* <img src={SunnyDays} alt="Sunny Days Logo" /> */}
        <div>
          <a href="#mission">
            <h4>Mission & Vision</h4>
          </a>

          <h4>|</h4>
          <a href="#faqs">
            <h4>FAQs</h4>
          </a>
        </div>
      </div>
    );
  }
}
