import React from "react";
import ReactDOM from "react-dom";
// import Countdown from "react-countdown";
import Countdown from "./components/Countdown";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF, FaDiscord } from "react-icons/fa";
import ASCE from "./images/much-love-asce.svg";
import Navbar from "./components/Navbar";

export default class Splash extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  }
  render() {
    return (
      <div className="splash-wrapper">
        <div className="splash">
          {/* <Countdown date={SUN_GOD_DATE} className="countdown" /> */}
          {/* <div className="play">
            <h3>PLAY</h3>
            <svg
              width="22"
              height="23"
              viewBox="0 0 22 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.1279 11.5273L0.851154 0.355225V22.6994L21.1279 11.5273Z"
                fill="white"
              />
            </svg>
          </div> */}

          <Countdown />
          <Navbar />
          {/* <div className="registerButton">
          <button>
            <a href="https://bit.ly/ASCESunnyDays" target="_blank">
              REGISTER
            </a>{" "}
          </button>
        </div> */}

          <svg
            width="49"
            height="47"
            viewBox="0 0 49 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="asce-logo"
          >
            <path
              d="M40.4287 14.113C35.5328 8.1072 29.696 3.42542 24.5636 0C19.2846 3.75782 13.5605 8.01706 8.8393 13.6679C4.52373 18.8229 -0.259466 25.8991 0.0109614 34.2373C0.039131 35.3246 0.309559 36.6317 0.74337 37.7641C2.42791 42.1473 6.92377 45.0657 12.5239 46.6826C9.74073 45.3981 7.59421 43.5502 6.64771 41.0825C6.32094 40.2318 6.12376 39.2458 6.09559 38.4289C5.89277 32.164 9.4872 26.8512 12.7267 22.9751C16.2761 18.7328 20.5747 15.5327 24.541 12.7101C28.4002 15.2848 32.7834 18.8004 36.4624 23.3075C39.8596 27.471 43.4484 33.2401 42.7498 39.4261C42.6146 40.6318 42.0174 41.9839 41.3132 42.9586C40.2991 44.3558 38.699 45.522 36.7384 46.4404C41.2005 45.0995 44.8963 43.0206 46.8964 40.2656C47.8372 38.9698 48.6316 37.1726 48.8063 35.5669C49.7246 27.3358 44.9527 19.6567 40.4287 14.113Z"
              fill="#FF776E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M24.4283 40.4234C23.0029 39.4769 21.3916 38.1811 20.0339 36.5191C18.7831 34.9867 17.4592 32.8571 17.7127 30.581C17.7634 30.1359 17.9831 29.6401 18.2423 29.2795C20.3832 26.3217 29.5495 26.1583 31.0144 29.9725C31.1327 30.288 31.2059 30.6486 31.2172 30.9472C31.2961 33.2571 29.9665 35.2177 28.7777 36.6431C27.4763 38.2093 25.8931 39.3868 24.4283 40.4234Z"
              fill="#FF776E"
            />
          </svg>

          <div>
            <div className="splash-icons">
              <a href="https://www.instagram.com/ucsdasce/" target="_blank">
                {" "}
                <AiOutlineInstagram />
              </a>
              <a href="https://www.facebook.com/ucsdasce/" target="_blank">
                <FaFacebookF />
              </a>
              <a href="https://bit.ly/ASCEDiscord" target="_blank">
                <FaDiscord />
              </a>
            </div>
            <div className="registerButton">
              <button>
                <a href="https://bit.ly/ASCESunnyDays" target="_blank">
                  <h3>REGISTER</h3>
                </a>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
