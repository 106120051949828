import React from "react";
import FooterImage from "../images/footer.svg";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <img src={FooterImage} alt="Sunny Days" />
      </div>
    );
  }
}
