import * as React from "react";
import { render } from "react-dom";
import { FullpageAccordion, Panel } from "react-fullpage-accordion";
import "react-fullpage-accordion/dist/react-fullpage-accordion.css";
import "./scss/App.scss";

const colors = {
  dark_blue: "#3EA8AA",
  yellow: "#FDFBCD",
  pink: "#FF776E",
  navy: "#557E9C",
  ocher: "#F2B941",
  brown: "#611008",
  light_blue: "#D3FFFD"
};

const AccordionTemp = () => {
  return (
    <div className="events">
      <FullpageAccordion height="80vh">
        <Panel itemId="0" background={require("./images/monday-postcard.jpg")}>
          {/* 
          NAME
          DATE and TIME
          BIO
          FACEBOOK EVENT */}

          <div className="innerEvents" style={{ color: colors.yellow }}>
            <h6>Sunny Days Presents</h6>
            <h2>GRYFFIN <br /> (DJ Set)</h2>
            <br />
            <br />
            <h4>MAY 10th | 7PM - 8:15PM PST</h4>
            <h5>
              With his debut album Gravity on the horizon, Gryffin plans to
              deliver his most fully realized work to date, a sonically
              adventurous body of work that instantly transports the audience
              into a universe of his own making.
            </h5>
            <button style={{ backgroundColor: colors.yellow }}>
              <p>
                <a href="https://www.facebook.com/events/813471416248699" target="_blank" style={{ color: colors.dark_blue }}>
                  Facebook Event Link
                </a>
              </p>
            </button>
          </div>
        </Panel>
        <Panel itemId="1" background={require("./images/tuesday-postcard.jpg")}>
          <div className="innerEvents" style={{ color: colors.pink }}>
            <h6>Sunny Days Presents</h6>
            <h2>Chloe x Halle</h2>
            <br />
            <br />
            <h4>MAY 11th | 7PM - 8PM PST</h4>
            <h5>
              Chloe x Halle’s music—which they write, arrange, produce and
              engineer themselves—is uplifting and delightfully enchanting, but
              the ladies were eager to show a different side of themselves.
            </h5>
            <button style={{ backgroundColor: colors.pink }}>
              <p>
                <a
                  href="https://www.facebook.com/events/813471426248698"
                  target="_blank"
                  style={{ color: colors.yellow }}
                >
                  Facebook Event Link
                </a>
              </p>
            </button>
          </div>
        </Panel>
        <Panel
          itemId="2"
          background={require("./images/wednesday-postcard.jpg")}
        >
          <div className="innerEvents" style={{ color: colors.yellow }}>
            <h6>Sunny Days Presents</h6>
            <h2>Aubrey Plaza</h2>
            <br />
            <br />
            <h4>MAY 12th | 7PM - 8PM PST</h4>
            <h5>
              Aubrey Plaza is best known for portraying April Ludgate on Parks
              and Recreation. Aubrey Plaza recently starred in Lawrence Michael
              Levine's Black Bear and recieved critical acclaim for her role in
              Ingrid Goes West, which she produced.
            </h5>
            <button style={{ backgroundColor: colors.yellow }}>
              <p>
                <a
                  href="https://www.facebook.com/events/813471412915366"
                  target="_blank"
                  style={{ color: colors.pink }}
                >
                  Facebook Event Link
                </a>
              </p>
            </button>
          </div>
        </Panel>
        <Panel
          itemId="3"
          background={require("./images/thursday-postcard.jpg")}
        >
          <div className="innerEvents" style={{ color: colors.ocher }}>
            <h6>Sunny Days Presents</h6>
            <h2>Omar Apollo</h2>
            <br />
            <br />
            <h4>MAY 13th | 7PM - 8:15PM PST</h4>
            <h5>
              After dropping out of college only 2 weeks in, Omar Apollo, a
              22-year old, first generation Mexican-American singer from
              Indiana, began writing and recording his own mix of jazz, R&B,
              funk, alternative, soul, and pop music. 2019 saw Omar complete
              back-to-back sell-out headline tours throughout North America.
            </h5>
            <button style={{ backgroundColor: colors.ocher }}>
              <p>
                <a
                  href="https://www.facebook.com/events/813471432915364"
                  style={{ color: colors.navy }}
                  target="_blank"
                >
                  Facebook Event Link
                </a>
              </p>
            </button>
          </div>
        </Panel>
        <Panel itemId="4" background={require("./images/friday-postcard.jpg")}>
          <div className="innerEvents" style={{ color: colors.navy }}>
            <h6>Sunny Days Presents</h6>
            <h2>Eric Nam</h2>
            <br />
            <br />
            <h4>MAY 14th | 7PM - 8PM PST</h4>
            <h5>
              Eric Nam is one of the most popular and recognizable celebrities
              in Asia and has been named GQ Korea’s Man of the Year, Forbes 30
              Under 30 Asia, and YouTube’s Trending Artist On The Rise.
            </h5>
            <button style={{ backgroundColor: colors.navy }}>
              <p>
                <a
                  target="_blank"
                  href="https://www.facebook.com/events/813471422915365"
                  style={{ color: colors.ocher }}
                >
                  Facebook Event Link
                </a>
              </p>
            </button>
          </div>
        </Panel>
        <Panel
          itemId="5"
          background={require("./images/saturday-postcard.jpg")}
        >
          <div className="innerEvents" style={{ color: colors.brown }}>
            <h6>A Conversation with</h6>
            <h2>Hasan Minhaj</h2>
            <br />
            <br />
            <h4>MAY 15th | 6PM - 7PM PST</h4>
            <h5>
              Hasan Minhaj was the host and creator of the weekly comedy show
              Patriot Act with Hasan Minhaj that premiered on Netflix in October
              2018. A first generation American, Minhaj joined The Daily Show
              with Jon Stewart as a correspondent.
            </h5>
            <button style={{ backgroundColor: colors.brown }}>
              <p>
                <a
                  target="_blank"
                  href="https://www.facebook.com/events/813471429582031"
                  style={{ color: colors.light_blue }}
                >
                  Facebook Event Link
                </a>
              </p>
            </button>
          </div>
        </Panel>
        <Panel itemId="6" background={require("./images/ongoing-postcard.jpg")}>
          <div className="innerEvents" style={{ color: colors.light_blue }}>
            <h6>Sunny Days Presents</h6>
            <h2>Sunny Vibes Playlist</h2>
            <br />
            <br />
            <h5>
              Feel the Sunny Vibes with our Spotify playlist curated by ASCE
              Bear and the Sunny Days team. Listen to our favorites and get
              ready for Sunny Days.
            </h5>
            <button style={{ backgroundColor: colors.light_blue }}>
              <p>
                <a
                  target="_blank"
                  href="https://bit.ly/SunnyDayVibes"
                  style={{ color: colors.brown }}
                >
                  Playlist Link
                </a>
              </p>
            </button>
          </div>
        </Panel>
      </FullpageAccordion>
    </div>
  );
};

export default AccordionTemp;
