import * as React from "react";
import { render } from "react-dom";
import "./scss/App.scss";
import Splash from "./Splash";
import Navbar from "./components/Navbar";
import AccordionTemp from "./AccordionTemp";
import FAQs from "./FAQs";
import Footer from "./components/Footer";
import MobileAccordion from "./MobileAccordion";

const App = () => {
  return (
    <div>
      <Splash />
      <div id="desktop_accordion">
        <AccordionTemp />
      </div>
      <div id="mob_accordion">
        <MobileAccordion />
      </div>
      <FAQs />
      <Footer />
    </div>
  );
};

export default App;
