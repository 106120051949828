import React, { useEffect, useState } from "react";

function Countdown() {
 
  const calculateTimeLeft = () => {

    let year = new Date().getFullYear();
    const difference = +new Date(year,4,11) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24) - 5,
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    timerComponents.push( 

        <span>
          <h3>{timeLeft[interval]}</h3>
          <h6>{interval}</h6>
        </span>
    );
  });
  return <div className="countdown">{timerComponents}</div>;
}

export default Countdown;
