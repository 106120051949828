import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const colors = {
  dark_blue: "#3EA8AA",
  yellow: "#FDFBCD",
  pink: "#FF776E",
  navy: "#557E9C",
  ocher: "#F2B941",
  brown: "#611008",
  light_blue: "#D3FFFD"
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const MobileAccordion = () => {
  const classes = useStyles();
  return (
    <div className="acc_mob">
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="mobile-accordion"
          style={{ backgroundColor: colors.dark_blue, color: "#fdfbcd" }}
        >
          <img src={require("./images/post-mobile-monday.svg")} />
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: colors.dark_blue }}>
          <Typography>
            <div className="innerEvents" style={{ color: colors.yellow }}>
              <img src={require("./images/gryffin.jpg")} className="lead" />
              <h6>Sunny Days Presents</h6>
              <h2>GRYFFIN</h2>
              <br />
              <br />
              <h4>MAY 10th | 7PM - 8:15PM PST</h4>
              <h5>
                With his debut album Gravity on the horizon, Gryffin plans to
                deliver his most fully realized work to date, a sonically
                adventurous body of work that instantly transports the audience
                into a universe of his own making.
              </h5>
              <button
                style={{ backgroundColor: colors.yellow }}
                className="lead_button"
              >
                <p>
                  <a
                    href="https://www.facebook.com/events/813471416248699"
                    target="_blank"
                    style={{ color: colors.dark_blue }}
                  >
                    Facebook Event Link
                  </a>
                </p>
              </button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="mobile-accordion"
          style={{ backgroundColor: colors.yellow, color: "#fdfbcd" }}
        >
          <img src={require("./images/post-mobile-tuesday.svg")} />
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: colors.yellow }}>
          <Typography>
            <div className="innerEvents" style={{ color: colors.pink }}>
              <img src={require("./images/chloe_halle.jpg")} className="lead" />
              <h6>Sunny Days Presents</h6>
              <h2>Chloe x Halle</h2>
              <br />
              <br />
              <h4>MAY 11th | 7PM - 8PM PST</h4>
              <h5>
                Chloe x Halle’s music—which they write, arrange, produce and
                engineer themselves—is uplifting and delightfully enchanting,
                but the ladies were eager to show a different side of
                themselves.
              </h5>
              <button
                style={{ backgroundColor: colors.pink }}
                className="lead_button"
              >
                <p>
                  <a
                    href="https://www.facebook.com/events/813471426248698"
                    target="_blank"
                    style={{ color: colors.yellow }}
                  >
                    Facebook Event Link
                  </a>
                </p>
              </button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="mobile-accordion"
          style={{ backgroundColor: colors.pink, color: "#fdfbcd" }}
        >
          <img src={require("./images/post-mobile-wednesday.svg")} />
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: colors.pink }}>
          <Typography>
            <div className="innerEvents" style={{ color: colors.yellow }}>
              <img src={require("./images/plaza.jpg")} className="lead" />
              <h6>Sunny Days Presents</h6>
              <h2>Aubrey Plaza</h2>
              <br />
              <br />
              <h4>MAY 12th | 7PM - 8PM PST</h4>
              <h5>
                Aubrey Plaza is best known for portraying April Ludgate on Parks
                and Recreation. Aubrey Plaza recently starred in Lawrence
                Michael Levine's Black Bear and recieved critical acclaim for
                her role in Ingrid Goes West, which she produced.
              </h5>
              <button
                style={{ backgroundColor: colors.yellow }}
                className="lead_button"
              >
                <p>
                  <a
                    href="https://www.facebook.com/events/813471412915366"
                    target="_blank"
                    style={{ color: colors.pink }}
                  >
                    Facebook Event Link
                  </a>
                </p>
              </button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="mobile-accordion"
          style={{ backgroundColor: colors.navy, color: "#fdfbcd" }}
        >
          <img src={require("./images/post-mobile-thursday.svg")} />
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: colors.navy }}>
          <Typography>
            <div className="innerEvents" style={{ color: colors.ocher }}>
              <img src={require("./images/omar.jpg")} className="lead" />
              <h6>Sunny Days Presents</h6>
              <h2>Omar Apollo</h2>
              <br />
              <br />
              <h4>MAY 13th | 7PM - 8:15PM PST</h4>
              <h5>
                After dropping out of college only 2 weeks in, Omar Apollo, a
                22-year old, first generation Mexican-American singer from
                Indiana, began writing and recording his own mix of jazz, R&B,
                funk, alternative, soul, and pop music. 2019 saw Omar complete
                back-to-back sell-out headline tours throughout North America.
              </h5>
              <button
                style={{ backgroundColor: colors.ocher }}
                className="lead_button"
              >
                <p>
                  <a
                    href="https://www.facebook.com/events/813471432915364"
                    target="_blank"
                    style={{ color: colors.navy }}
                  >
                    Facebook Event Link
                  </a>
                </p>
              </button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="mobile-accordion"
          style={{ backgroundColor: colors.ocher, color: "#fdfbcd" }}
        >
          <img src={require("./images/post-mobile-friday.svg")} />
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: colors.ocher }}>
          <Typography>
            <div className="innerEvents" style={{ color: colors.navy }}>
              <img src={require("./images/eric.jpg")} className="lead" />
              <h6>Sunny Days Presents</h6>
              <h2>Eric Nam</h2>
              <br />
              <br />
              <h4>MAY 14th | 7PM - 8PM PST</h4>
              <h5>
                Eric Nam is one of the most popular and recognizable celebrities
                in Asia and has been named GQ Korea’s Man of the Year, Forbes 30
                Under 30 Asia, and YouTube’s Trending Artist On The Rise.
              </h5>
              <button
                style={{ backgroundColor: colors.navy }}
                className="lead_button"
              >
                <p>
                  <a
                    href="https://www.facebook.com/events/813471422915365"
                    target="_blank"
                    style={{ color: colors.ocher }}
                  >
                    Facebook Event Link
                  </a>
                </p>
              </button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="mobile-accordion"
          style={{ backgroundColor: colors.light_blue, color: "#fdfbcd" }}
        >
          <img src={require("./images/post-mobile-saturday.svg")} />
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: colors.light_blue }}>
          <Typography>
            <div className="innerEvents" style={{ color: colors.brown }}>
              <img src={require("./images/hasan.jpg")} className="lead" />
              <h6>A Conversation with</h6>
              <h2>Hasan Minhaj</h2>
              <br />
              <br />
              <h4>MAY 15th | 6PM - 7PM PST</h4>
              <h5>
                Hasan Minhaj was the host and creator of the weekly comedy show
                Patriot Act with Hasan Minhaj that premiered on Netflix in
                October 2018. A first generation American, Minhaj joined The
                Daily Show with Jon Stewart as a correspondent.
              </h5>
              <button
                style={{ backgroundColor: colors.brown }}
                className="lead_button"
              >
                <p>
                  <a
                    href="https://www.facebook.com/events/813471429582031"
                    target="_blank"
                    style={{ color: colors.light_blue }}
                  >
                    Facebook Event Link
                  </a>
                </p>
              </button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="mobile-accordion"
          style={{ backgroundColor: colors.brown, color: "#fdfbcd" }}
        >
          <img src={require("./images/post-mobile-ongoing.svg")} />
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: colors.brown }}>
          <Typography>
            <div className="innerEvents" style={{ color: colors.light_blue }}>
              <img src={require("./images/post-ongoing.jpg")} className="lead" />
              <h6>Sunny Days Presents</h6>
              <h2>Sunny Vibes Playlist</h2>
              <br />
              <br />
              <h5>
                Feel the Sunny Vibes with our Spotify playlist curated by ASCE
                Bear and the Sunny Days team. Listen to our favorites and get
                ready for Sunny Days.
              </h5>
              <button
                style={{ backgroundColor: colors.light_blue }}
                className="lead_button"
              >
                <p>
                  <a
                    target="_blank"
                    href="https://bit.ly/SunnyDayVibes"
                    style={{ color: colors.brown }}
                  >
                    Playlist Link
                  </a>
                </p>
              </button>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MobileAccordion;
