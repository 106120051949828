import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const FAQs = () => {
  const classes = useStyles();
  return (
    <div className="mission">
      <div id="mission">
        <h2>MISSION & VISION</h2>
        <h4>
          To curate a week long of events featuring talent and giveaways in lieu
          of the Sun God Festival. We aspire to bring students together during a
          very unique time to foster community and hope for sunnier days ahead.
          <br />
          <br />
          Sunny Days is a student-curated week-long virtual event series brought
          to you by the team who planned the Sun God Festival. This new event
          will feature musical talent, personalities, and the week will be
          filled with giveaways and activities students can participate in. As
          we wait to celebrate together physically, Sunny Days hopes to provide
          students with a sense of community and happiness after a year of
          virtual learning.
        </h4>
      </div>

      <div className="faqs" id="faqs">
        <h2>FREQUENTLY ASKED QUESTIONS</h2>
        <div className={classes.root}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className = "temp"
              style={{ backgroundColor: "#FF776E", color: "#fdfbcd" }}
            >
              <Typography className={classes.heading}>
                <h4>Rules regarding registrants</h4>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="temp">
              <Typography>
                <h4>
                  - UC San Diego undergraduate or graduate students, UCSD
                  faculty, and staff may attend 2021 Sunny Days virtual events.
                  <br />
                  - Graduating class of 2020 is eligible to attend Sunny Days.
                  (Graduated in the following quarters: Fall 2019, Winter 2020,
                  or Spring 2020)
                  <br />- Anyone who is not affiliated with UC San Diego as of
                  the 2020-2021 school year OR a part of the 2020 graduating
                  class is NOT eligible to attend 2021 Sunny Days virtual
                  events.
                </h4>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              className = "temp"
              style={{ backgroundColor: "#FF776E", color: "#fdfbcd" }}
            >
              <Typography>
                <h4>Rules regarding giveaways and M&Gs</h4>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className = "temp">
              <Typography>
                <h4>
                  - Giveaways are only open to UC San Diego current
                  undergraduate students <br />- Winners must provide their PID,
                  UCSD email address, and a continental US address we can ship
                  the items to.
                </h4>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              className = "temp"
              style={{ backgroundColor: "#FF776E", color: "#fdfbcd" }}
            >
              <Typography className={classes.heading}>
                <h4>Rules regarding care packages</h4>
              </Typography>
            </AccordionSummary>
            <AccordionDetails className = "temp">
              <Typography>
                <h4>
                  - First come, first serve for 500 number of packages.
                  <br />- First 500 registrants will receive a care package.
                </h4>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
